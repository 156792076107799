import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import H1 from '../../components/h1';
import { configImagesUrlSelector } from '../../state/selectors/config';

import messages from './messages';
import styles from './styles.cssm';

const MaskBanner = ({ configImagesUrl, intl: { formatMessage } }) => {
  return (
    <div className={`${styles.maskBannerContainer} bg-greenLight mt3`}>
      <div className="text-center px4 py3">
        <ImageLoader
          alt={formatMessage(messages.pickupMaskupImageAltText)}
          src={`${configImagesUrl}/icons/mask-white.png`}
          width="64px"
        />
        <H1 className="color-textBlack text_sm text-semibold mt2" size="sm">
          <FormattedMessage {...messages.pickupMaskupHeader} />
        </H1>
        <p className="color-textBlack text_sm pt1 px5">
          <FormattedMessage {...messages.pickupMaskupText} />
        </p>
      </div>
    </div>
  );
};

const select = (state) => ({
  configImagesUrl: configImagesUrlSelector(state),
});

export default connect(select)(injectIntl(MaskBanner));
