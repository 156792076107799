import { trackEvent } from 'shared/app/utils/uo-tracking';

export const UO_GUEST_SIGN_IN = 'sign in';
export const UO_GUEST_SIGN_UP = 'sign up';

/**
 * @param {{click_target: string, cta_placement: string}}
 * eventAttributes - Attributes to be passed to the trackEvent call
 * @returns {Promise<void>} The event has been sent.
 */
export const trackGuestSrCtaClick = (eventAttributes) =>
  trackEvent('GUEST_SR_CTA_CLICK', eventAttributes);

/**
 * @returns {Promise<void>} The event has been sent.
 */
export const trackCheckoutAsGuestClick = () =>
  // eslint-disable-next-line camelcase
  trackEvent('MOP_GUEST_CHECKOUT_CLICK', { customer_type: 'guest' });
