import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Field from '@starbucks-web/pattern-library/lib/components/field';
import FrapContainer from 'shared/app/components/frap-container';
import Frap from '@starbucks-web/pattern-library/lib/components/frap';

import { prefixErrorMessage } from 'shared/app/components/a11y-error-message-prefix';
import { isVerifyingMfaCodeSelector } from '../../state/selectors';
import messages from '../shared-messages';
import styles from './styles.cssm';

const MfaChallengeEnterCodeOverlayForm = ({
  fields,
  onResendCode,
  onOtherOptions,
  disableButtons,
}) => {
  const isVerifyingMfaCode = useSelector(isVerifyingMfaCodeSelector);

  const { verificationCode: field } = fields;
  const errorMessage = field.errorMessage || messages.enterMfaCodeError;

  return (
    <Fragment>
      <Field
        autoFocus
        className="size11of12 text-sm color-textBlackSoft"
        data-e2e="verificationCode"
        id="verificationCode"
        {...field.input}
        error={field.error && (field.wasValid || field.wasSubmitted)}
        errorMessage={prefixErrorMessage(errorMessage)}
      >
        <FormattedMessage {...messages.yourMfaCodeLabel} />
      </Field>
      <Button
        className="mxn3 mt4"
        data-e2e="resendCodeButton"
        disabled={disableButtons}
        onClick={onResendCode}
        visualStyle="textOnly"
      >
        <FormattedMessage {...messages.resendCodeLabel} />
      </Button>
      <Button
        className="mxn3 block"
        data-e2e="getCodeAnotherWay"
        disabled={disableButtons}
        onClick={onOtherOptions}
        visualStyle="textOnly"
      >
        <FormattedMessage {...messages.getCodeAnotherWayButton} />
      </Button>
      <FrapContainer className={styles.frap} relativeAbove="md">
        <Frap
          data-e2e="verifyButton"
          loading={isVerifyingMfaCode}
          type="submit"
        >
          <FormattedMessage {...messages.verifyCodeLabel} />
        </Frap>
      </FrapContainer>
    </Fragment>
  );
};

export default MfaChallengeEnterCodeOverlayForm;
