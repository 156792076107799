import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import curbsideIcon from '@starbucks-web/pattern-library/lib/icons/pickup-curbside';
import inStoreIcon from '@starbucks-web/pattern-library/lib/icons/pickup-in-store';
import outdoorIcon from '@starbucks-web/pattern-library/lib/icons/pickup-outdoor';
import driveThruIcon from '@starbucks-web/pattern-library/lib/icons/pickup-drive-thru';

import {
  IN_STORE_FEATURE_CODE,
  DRIVE_THRU_FEATURE_CODE,
  CURBSIDE_FEATURE_CODE,
  OUTDOOR_FEATURE_CODE,
} from '../../../universal/pickup-feature-codes';

import messages from './messages';
import styles from './styles.cssm';

const HowToPickup = ({ option, name }) => {
  const options = {
    [IN_STORE_FEATURE_CODE]: {
      icon: inStoreIcon,
      header: messages.pickupInStoreHeader,
      text: messages.pickupInStoreOption,
      name,
    },
    [DRIVE_THRU_FEATURE_CODE]: {
      icon: driveThruIcon,
      header: messages.pickupDriveThruHeader,
      text: messages.pickupDriveThruOption,
      name,
    },
    [CURBSIDE_FEATURE_CODE]: {
      icon: curbsideIcon,
      header: messages.pickupCurbsideHeader,
      text: messages.pickupCurbsideOption,
    },
    [OUTDOOR_FEATURE_CODE]: {
      icon: outdoorIcon,
      header: messages.pickupOutdoorHeader,
      text: messages.pickupOutdoorOption,
    },
  };

  const opt = options[option] || options[IN_STORE_FEATURE_CODE];

  return (
    <div className="mt4 text-sm">
      <Heading className="text-sm text-semibold inline" tagName="h2">
        <FormattedMessage {...messages.howToPickup} />
      </Heading>
      <div
        className={`${styles.overlayPickupOption} mt3 px1 py3 flex items-center`}
      >
        <div className={`${styles.iconWrapper}`}>
          <Icon className="color-textBlackSoft" path={opt.icon} />
        </div>
        <div className="pl2 pr3 flex-grow">
          <Heading className="pb1 text-semibold" tagName="h3">
            <FormattedMessage {...opt.header} />
          </Heading>
          <div className="text-xxs">
            <FormattedMessage {...opt.text} values={{ name: opt.name }} />
          </div>
        </div>
      </div>
    </div>
  );
};

HowToPickup.propTypes = {
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default HowToPickup;
