import React from 'react';
import { FormattedMessage } from 'react-intl';

import Rule from '@starbucks-web/pattern-library/lib/components/rule';

import messages from './messages';
import FormattedMoney from '../../formatted-money';
import Heading from '@starbucks-web/pattern-library/lib/components/heading';

const SummaryRecipients = ({
  numberOfRecipients,
  recipients,
  amount,
  children,
}) => {
  return (
    <React.Fragment>
      <Heading className="text-bold text-upper text-xs mt4" tagName="h3">
        <FormattedMessage
          {...messages.recipientHeading}
          values={{ numberOfRecipients }}
        />
      </Heading>
      <ul>
        {recipients?.map((recipient, i) => {
          return (
            <React.Fragment key={`email-summary-recipient${i}`}>
              <li className="flex justify-between py3">
                <p className="flex flex-column">
                  <span className="text-semibold">
                    <FormattedMessage {...messages.to} />{' '}
                    {recipient.recipientName}
                  </span>
                  <span>{recipient.recipientEmail}</span>
                </p>
                <FormattedMoney className="text-semibold" value={amount} />
                {children || null}
                {/** Copy link CTA WG-11449 */}
              </li>
              {i === recipients.length - 1 ? null : <Rule weight="thin" />}
            </React.Fragment>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default SummaryRecipients;
