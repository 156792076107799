import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { signedInSelector } from 'shared/app/bundles/user';

import H1 from 'shared/app/components/h1';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import warningSolid from '@starbucks-web/pattern-library/lib/icons/warning-solid';
import Button from '@starbucks-web/pattern-library/lib/components/button';

import styles from './styles.cssm';
import { SUCCESS_PURCHASE_STATUS, PENDING_PURCHASE_STATUS } from '../constants';
import messages from './messages';

const SuccessStatus = ({ numberOfRecipients, recipients }) => {
  const recipientEmail = recipients[0].recipientEmail;
  return (
    <div className="borderRadius-sm bg-greenLight p3 text-center">
      <H1 className="text-bold" size="md">
        <FormattedMessage
          {...messages.successHeader}
          values={{ numberOfRecipients }}
        />
      </H1>
      <p className="text-xs">
        <FormattedMessage
          {...messages.successBody}
          values={{ numberOfRecipients, recipientEmail }}
        />
      </p>
    </div>
  );
};

const PendingStatus = ({ numberOfRecipients }) => {
  const isAuthenticated = useSelector(signedInSelector);
  return (
    <div className="borderRadius-sm bg-neutralWarm p3 text-center">
      <H1 className="text-bold" size="md">
        <FormattedMessage
          {...messages.pendingHeader}
          values={{ numberOfRecipients }}
        />
      </H1>
      <p className="text-xs">
        {isAuthenticated ? (
          <FormattedMessage
            {...messages.pendingBody}
            values={{ numberOfRecipients }}
          />
        ) : (
          <FormattedMessage
            {...messages.unAuthPendingBody}
            values={{ numberOfRecipients }}
          />
        )}
      </p>
    </div>
  );
};

const ErrorStatus = () => {
  return (
    <div className={`borderRadius-sm p3 text-center ${styles.errorStatus}`}>
      <Icon className="color-red" path={warningSolid} size="50px" />
      <H1 className="text-bold" size="md">
        <FormattedMessage {...messages.errorHeader} />
      </H1>
      <p className="text-xs">
        <FormattedMessage {...messages.errorBody} />
      </p>
      <Button
        className="mt4"
        color="black"
        tagName={Link}
        to="/gift"
        visualStyle="positive"
      >
        <FormattedMessage {...messages.tryAgainButton} />
      </Button>
    </div>
  );
};

const StatusBanner = ({
  mostRecentPurchase: { purchaseStatus, recipients, numberOfRecipients },
}) => {
  if (purchaseStatus === SUCCESS_PURCHASE_STATUS) {
    return (
      <SuccessStatus
        numberOfRecipients={numberOfRecipients}
        recipients={recipients}
      />
    );
  }
  if (purchaseStatus === PENDING_PURCHASE_STATUS) {
    return <PendingStatus numberOfRecipients={numberOfRecipients} />;
  }
  return <ErrorStatus />;
};

export default StatusBanner;
