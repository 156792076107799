import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Heading from '@starbucks-web/pattern-library/lib/components/heading';

import LegacyPaddedContentColumn from 'shared/app/components/legacy-padded-content-column';
import OverlayWithCloseMessage from 'shared/app/components/overlay-with-close-message';
import { userMfaVerifiedPhoneSelector } from 'shared/app/bundles/user/state/selectors/mfa';

import MfaChallengeEnterCodeOverlayContainer from './container';

import messages from '../shared-messages';

export const MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID =
  'MfaChallengeEnterCodeOverlayId';

const MfaChallengeEnterCodeOverlay = ({
  verificationType = 'secureSession',
  onCodeSuccess,
  onCodeError,
  phoneId,
  phoneNumber,
  phoneSmsState,
  username,
  password,
  closeCallback,
}) => {
  const { formatMessage } = useIntl();

  const isSecureSessionVerificationType = verificationType === 'secureSession';

  const userMfaVerifiedPhone = useSelector(userMfaVerifiedPhoneSelector);

  const phoneDetails = isSecureSessionVerificationType
    ? {
        id: userMfaVerifiedPhone?.id,
        phoneNumber: userMfaVerifiedPhone?.phoneNumber,
        phoneSmsState: userMfaVerifiedPhone?.phoneSmsState,
      }
    : {
        id: phoneId,
        phoneNumber,
        phoneSmsState,
      };

  return (
    <OverlayWithCloseMessage
      alignment="fullscreen"
      closeCallback={closeCallback}
    >
      <LegacyPaddedContentColumn className="frapPadding size12of12" size="sm">
        <Heading
          className="text-bold pb5"
          id={MFA_CHALLENGE_ENTER_CODE_OVERLAY_ID}
          size="lg"
          tagName="h2"
        >
          {formatMessage(
            isSecureSessionVerificationType
              ? messages.verifyNumberHeading
              : messages.enterMfaCodeHeading
          )}
        </Heading>
        <p className="pb4">
          {formatMessage(
            isSecureSessionVerificationType
              ? messages.verifyNumberSubHeading
              : messages.enterMfaCode,
            {
              phoneNumber: isSecureSessionVerificationType
                ? userMfaVerifiedPhone?.phoneNumber
                : phoneNumber,
            }
          )}
        </p>
        <MfaChallengeEnterCodeOverlayContainer
          data-e2e="mfaChallengeOverlay"
          onCodeError={onCodeError}
          onCodeSuccess={onCodeSuccess}
          password={password}
          phone={phoneDetails}
          username={username}
          verificationType={verificationType}
        />
      </LegacyPaddedContentColumn>
    </OverlayWithCloseMessage>
  );
};

MfaChallengeEnterCodeOverlay.propTypes = {
  phoneId: PropTypes.string,
  phoneNumber: PropTypes.string,
  phoneSmsState: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  verificationType: PropTypes.string,
  onCodeSuccess: PropTypes.func,
  onCodeError: PropTypes.func,
};

export default MfaChallengeEnterCodeOverlay;
