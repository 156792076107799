import { getBffBaseUrl } from 'shared/app/utils/config-helper';

export const fetchInitialDrinkFinderQuestion =
  () =>
  (dispatch, getState, { apiFetch }) => {
    const DRINK_FINDER_URL = `${getBffBaseUrl()}/bff/drink-finder`;

    const options = {
      method: 'POST',
      // when the request details are all empty we'll get back the first question
      // and its potential answers as options, plus a new dfSessionId (drink finder session id)
      body: {
        questionsAns: [],
        dfSessionId: '',
        dfSessionState: [],
      },
    };

    return apiFetch(DRINK_FINDER_URL, options);
  };
