import { defineMessages } from 'react-intl';

export default defineMessages({
  summaryHeading: {
    id: 'shared.giftReceipt.summary.summaryHeading',
    defaultMessage: 'Your eGift order',
    description: 'Heading for summary',
  },
  personalNote: {
    id: 'shared.giftReceipt.summary.personalNote',
    defaultMessage:
      'Personal note{numberOfRecipients, plural, one {} other { to all}}',
    description: 'Heading for user created eGift message',
  },
  orderPending: {
    id: 'shared.giftReceipt.summary.orderPending',
    defaultMessage:
      'Preparing to send your {numberOfRecipients, plural, one {eGift} other {eGifts}} ...',
    description: 'Indicates order is still processing',
  },
  recipientHeading: {
    id: 'shared.giftReceipt.summary.recipientHeading',
    defaultMessage:
      '{numberOfRecipients} {numberOfRecipients, plural, one {Recipient} other {Recipients}}:',
    description: 'Heading before listing recipients',
  },
  to: {
    id: 'shared.giftReceipt.summary.to',
    defaultMessage: 'To:',
    description: 'before recipient name',
  },
  checkAgainCta: {
    id: 'shared.giftReceipt.summary.checkAgainCta',
    defaultMessage: 'Check again',
    description: 'Check again cta on pending',
  },
});
