import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import FormContainer from '@starbucks-web/pattern-library/lib/components/form-container';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  addNotification,
  showUnexpectedErrorNotification,
} from 'shared/app/shell/state/action-creators/notifications';

import MfaChallengeOtherOptionsOverlayForm from './form';
import {
  sendMfaCode,
  setMfaCodeDeliveryMethod,
} from '../../state/action-creators';
import {
  isSendingMfaCodeSelector,
  mfaChallengeRefSelector,
} from '../../state/selectors';

import sharedMessages from '../shared-messages';
import mapCodeToErrorMessage from '../error-messages';

const MfaChallengeOtherOptionsOverlayContainer = ({
  phone,
  onCodeSendSuccess,
  verificationType,
}) => {
  const challengeRef = useSelector(mfaChallengeRefSelector);
  const isSendingCode = useSelector(isSendingMfaCodeSelector);

  const [disableFrap, setDisableFrap] = useState(false);
  const { openModal, closeModal } = useModalContext();
  const dispatch = useDispatch();

  const handleSubmitError = (error) => {
    // graphql and bff responses provide different shape to access code
    const code = error?.data?.code || error?.code;
    const errorMessage = mapCodeToErrorMessage(code);
    if (errorMessage) {
      // Disable CTA buttons for "no more retries" errors
      const shouldDisableFap = ['501004', '691092'].indexOf(code) > -1;
      setDisableFrap(shouldDisableFap);

      dispatch(
        addNotification(<FormattedMessage {...errorMessage} />, {
          autoClose: false,
          type: 'error',
        })
      );
    } else {
      dispatch(showUnexpectedErrorNotification());
    }
  };

  const handleSubmit = ({ radioGroup }) => {
    const phoneNumber = phone?.phoneNumber;
    const phoneId = phone?.id;

    const deliveryMethod = radioGroup.toLowerCase().includes('voice')
      ? 'phoneVoice'
      : 'phoneSMS';

    dispatch(setMfaCodeDeliveryMethod(deliveryMethod));

    return dispatch(
      sendMfaCode({
        verificationType,
        phoneId,
        phoneNumber,
        deliveryMethod,
        challengeRef,
        openModal,
        onSendCodeSuccess: () => {
          dispatch(
            addNotification(
              <FormattedMessage {...sharedMessages.mfaCodeSentLabel} />,
              {
                autoClose: 3000,
              }
            )
          );
          closeModal();
          // Setting a delay to avoid a racing condition
          setTimeout(onCodeSendSuccess, 200);
        },
        onSendCodeError: (error) => handleSubmitError(error),
      })
    );
  };

  return (
    <FormContainer
      fields={{
        radioGroup: {
          input: {
            value: 'phoneSMS',
          },
        },
      }}
      onSubmit={handleSubmit}
    >
      <MfaChallengeOtherOptionsOverlayForm
        disableFrap={disableFrap}
        isSendingCode={isSendingCode}
        phoneNumber={phone?.phoneNumber}
      />
    </FormContainer>
  );
};

export default MfaChallengeOtherOptionsOverlayContainer;
