import { defineMessages } from 'react-intl';

export default defineMessages({
  orderNumber: {
    id: 'shared.giftReceipt.orderNumber',
    defaultMessage:
      '{orderNumber, select, false {Order # unavailable} other {Order # {orderNumber}}}',
    description: 'Order number info text',
  },
});
