import { defineMessages } from 'react-intl';

export default defineMessages({
  successHeader: {
    id: 'shared.giftReceipt.statusBanner.successHeader',
    description: 'Title for success status',
    defaultMessage:
      "We've sent your {numberOfRecipients, plural, one {eGift} other {eGifts}}!",
  },
  successBody: {
    id: 'shared.giftReceipt.statusBanner.successBody',
    description: 'Body copy for success status',
    defaultMessage:
      "{numberOfRecipients, plural, one {Your egift was sent to {recipientEmail}!} other {}} We'll {numberOfRecipients, plural, one {also} other {}} notify you when your {numberOfRecipients, plural, one {eGift has} other {eGifts have}} been opened.",
  },
  pendingHeader: {
    id: 'shared.giftReceipt.statusBanner.pendingHeader',
    description: 'Title for pending status',
    defaultMessage:
      'Preparing to send your {numberOfRecipients, plural, one {eGift} other {eGifts}}',
  },
  pendingBody: {
    id: 'shared.giftReceipt.statusBanner.pendingBody',
    description: 'Body copy for auth pending status',
    defaultMessage:
      'This shouldn’t take long! We’ll notify you when your {numberOfRecipients, plural, one {eGift has} other {eGifts have}} been sent — or, check its status below or in eGift History.',
  },
  unAuthPendingBody: {
    id: 'shared.giftReceipt.statusBanner.unAuthPendingBody',
    description: 'Body copy for unauth pending status',
    defaultMessage:
      'This shouldn’t take long! We’ll notify you when your {numberOfRecipients, plural, one {eGift has} other {eGifts have}} been sent — or, check its status below.',
  },
  errorHeader: {
    id: 'shared.giftReceipt.statusBanner.errorHeader',
    description: 'Title for error status',
    defaultMessage: 'Something went wrong!',
  },
  errorBody: {
    id: 'shared.giftReceipt.statusBanner.errorBody',
    description: 'Body copy for error stauts',
    defaultMessage:
      'If you were charged and do not see a refund, please contact customer support.',
  },
  tryAgainButton: {
    id: 'shared.giftReceipt.statusBanner.errorStatus.tryAgainButton',
    description: 'Button Label for creating another eGift',
    defaultMessage: 'Try creating again',
  },
});
