import { LOCATION_CHANGE } from 'redux-first-history';
import qs from 'query-string';
import { matchPath } from 'react-router';

const matchRoute = (routes, pathname) => {
  const routeKeys = Object.keys(routes);
  return routeKeys.reduce(
    (accum, routeKey) => {
      const match = matchPath(pathname, { path: routeKey, exact: true });
      // Use the first matching route in source order
      if (match && accum.route === null) {
        accum.route = match.path;
        accum.params = match.params;
        // Backward compatibility for selectors depending on `redux-little-router`'s data shape
        accum.result = routes[routeKey];
      }
      return accum;
    },
    { route: null, params: {} }
  );
};

const deriveRoute = (routes, location) => {
  const { pathname, search, hash, state } = location;
  const { params, route, result } = matchRoute(routes, pathname);
  return {
    pathname,
    hash,
    search,
    state: state || {},
    route,
    params,
    query: qs.parse(search),
    result,
  };
};

const getInitialState = (routes, location) => {
  if (location) {
    return {
      current: deriveRoute(routes, location),
      previous: null,
      routes,
    };
  }
  return { routes, current: null, previous: null };
};

export default ({ routes, location }) => {
  return (state = getInitialState(routes, location), action) => {
    switch (action.type) {
      case LOCATION_CHANGE:
        const previous = action.payload.isFirstRendering ? null : state.current;
        const current = deriveRoute(routes, action.payload.location);
        return { ...state, current, previous };
      default:
        return state;
    }
  };
};
