import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

import ImageLoader from '@starbucks-web/pattern-library/lib/components/image-loader';

import { signedInSelector } from '../../bundles/user';
import {
  coreAppBaseUrlSelector,
  isCoreAppUrlSelector,
} from '../../state/selectors/locales';
import { historyTippingEnabledSelector } from '../../state/selectors/transaction-history';

import messages from './messages';
import styles from './styles.cssm';

const OrderSummary = ({
  closeModal,
  isMostRecentOrderFromOwnedStore,
  orderAmount,
  orderItemsList,
}) => {
  const coreAppBaseUrl = useSelector(coreAppBaseUrlSelector);
  const isCoreAppUrl = useSelector(isCoreAppUrlSelector);
  const historyTippingEnabled = useSelector(historyTippingEnabledSelector);
  const signedIn = useSelector(signedInSelector);

  if (!orderItemsList) {
    return null;
  }

  const orderItemsCount = orderItemsList.reduce((count, item) => {
    count += item.quantity;
    return count;
  }, 0);

  // Due to a current limitation of CDX, it is not creating history records for $0 orders.
  // No receipt for guest users
  const showReceiptLink = signedIn && orderAmount > 0;

  const viewReceiptLink = isCoreAppUrl
    ? {
        props: { to: '/account/history' },
        tagName: Link,
      }
    : {
        props: { href: `${coreAppBaseUrl}/account/history` },
        tagName: 'a',
      };
  return (
    <Fragment>
      <div className="flex justify-between pt5">
        <p>
          <FormattedMessage
            {...messages.orderItems}
            values={{ orderItemsCount }}
          />
        </p>
        {showReceiptLink ? (
          <viewReceiptLink.tagName
            className="color-greenStarbucks text-noUnderline"
            {...viewReceiptLink.props}
            onClick={() => {
              closeModal();
            }}
          >
            {historyTippingEnabled && isMostRecentOrderFromOwnedStore ? (
              <FormattedMessage {...messages.pickupOrderReceiptAndTipping} />
            ) : (
              <FormattedMessage {...messages.pickupOrderViewReceipt} />
            )}
          </viewReceiptLink.tagName>
        ) : null}
      </div>
      <div className="flex mt3 flex-wrap">
        {orderItemsList.reduce((itemsList, item, idx) => {
          for (let qteIdx = 1; qteIdx <= item.quantity; qteIdx++) {
            itemsList.push(
              <ImageLoader
                alt={item?.product?.name}
                includeFallback={{ enabled: true }}
                key={`item-img${idx + 1}-${qteIdx}`}
                lazyLoad={{ enabled: false }}
                src={item.productImage}
                wrapInFlex={{
                  enabled: true,
                  flexEmbedWrapperClassName: `${styles.overlayImageBlock} mr3 mb2`,
                  height: 48,
                  width: 48,
                }}
              />
            );
          }
          return itemsList;
        }, [])}
      </div>
    </Fragment>
  );
};

export default OrderSummary;
