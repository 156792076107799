import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@starbucks-web/pattern-library/lib/components/button';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';

import messages from '../messages';
import presentIcon from './present-icon';

const SummaryPending = ({ numberOfRecipients, onClick = () => {} }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <Icon
        className={buttonLoading ? 'color-black20' : 'color-textBlackSoft'}
        clipRule="evenodd"
        fillRule="evenodd"
        path={presentIcon}
        size="48px"
        viewBox="0 0 48 48"
      />
      <p className={`my3 ${buttonLoading ? 'color-textBlackSoft' : ''}`}>
        <FormattedMessage
          {...messages.orderPending}
          values={{ numberOfRecipients }}
        />
      </p>
      <Button
        loading={buttonLoading}
        onClick={() => {
          setButtonLoading(!buttonLoading);
          dispatch(onClick).then(() => {
            setButtonLoading(buttonLoading);
          });
        }}
        visualStyle="positive"
      >
        <FormattedMessage {...messages.checkAgainCta} />
      </Button>
    </div>
  );
};

export default SummaryPending;
